import React from 'react';
import * as styles from './immobilienSection.module.scss';
import { Link } from "gatsby";

import AddressSVG from "../../icons/envato/address.svg"
import DeskSVG from "../../icons/envato/reception.svg"
import PhoneSVG from "../../icons/envato/phone.svg"
import WifiSVG from "../../icons/envato/wifi.svg"
import RocketSVG from "../../icons/envato/rocket.svg"
import ParkedCarSVG from "../../icons/envato/parked-car.svg"
import WorkSVG from "../../icons/envato/work.svg"
import ChevronRightSVG from "../../icons/fontawesome/light/chevron-right.svg"
import ChevronLeftSVG from "../../icons/fontawesome/light/chevron-left.svg"
import { Swiper, SwiperSlide } from "swiper/react"
import ImmobilienSliderCard from "./immobilienSliderCard"
import RcButton from "../../components/button/rcButton"

const ImmobilienSection = ( { showBack = false, address, children, image,
                              bizImmos, otherImmos = [],
                              className = '' } ) => {

  return <div className={" row py-4 py-lg-5 " + styles.immoSection + " " + className}>

      <div className="col-12 col-xl-7">

          { showBack &&
            <div className={"mb-4"}>
              <Link to={"/immobilien"} >
                <div className="d-flex align-items-center">
                  <ChevronLeftSVG className={"mr-2 svgFill"} />
                  Zurück zur Übersicht
                </div>
              </Link>
            </div>
          }

          <h4>Gewerbeimmobilien</h4>

          <h5 className={"mt-4"}>Business- & Innovationszentrum Wels</h5>

          <p className={styles.address + " d-flex align-items-center mb-3"}>
            <AddressSVG className={"svgFill"} />&nbsp;
            { address }
          </p>

          <p className={"mb-2 mt-1"}>{ children }</p>

          <div className="row">

            <div className="col-6 d-flex align-items-center">
              <div className={styles.badge}>
                <DeskSVG className={"mr-2 mb-1"} />
                Info
              </div>
            </div>

            <div className="col-6 d-flex align-items-center">
              <div className={styles.badge}>
                <PhoneSVG className={"mr-2 mb-1 svgFill "} />
                Büroservice
              </div>
            </div>

            <div className="col-6 d-flex align-items-center">
              <div className={styles.badge}>
                <WifiSVG className={"mr-2 mb-1"} />
                Telefon & Internet
              </div>
            </div>


            <div className="col-6 d-flex align-items-center">
              <div className={styles.badge}>
                <RocketSVG className={"mr-2 mb-1"} />
                Startup-Bonus
              </div>
            </div>


            <div className="col-6 d-flex align-items-center">
              <div className={styles.badge}>
                <ParkedCarSVG className={"mr-2 mb-1"} />
                gratis Parken
              </div>
            </div>

            <div className="col-6 d-flex align-items-center">
              <div className={styles.badge}>
                <WorkSVG className={"mr-2 mb-1"} />
                Seminar-Räume
              </div>
            </div>


          </div>
      </div>

      <div className="col-5 d-none d-xl-block">
          <img src={image} className={"mt-6 img-fluid w-100"} />
      </div>

    {bizImmos && bizImmos.length > 0 &&
    <div className={"col-12 mt-3"}>
      <div className="p-relative">

        <h5 className={"mb-2"}>BIZ Immobilien</h5>

        <Swiper className={"w-100 " + styles.swiper} loop={true} spaceBetween={20} slidesPerView={'auto'} navigation grabCursor={true}>

          {bizImmos.map(
            i => {
              return <SwiperSlide key={i.id} className={styles.sliderCard}>
                <ImmobilienSliderCard immo={i}/>
              </SwiperSlide>;
            }
          )}
        </Swiper>


        <div className="text-right">
          <RcButton className={"mt-3"}>
            <Link to={"/immobilien/biz"} className={"d-flex text-white align-items-center"}>
              <ChevronRightSVG className={"svgFill"} />
              Alle BIZ-Angebote ansehen
            </Link>
          </RcButton>
        </div>

      </div>
    </div>
    }

    {otherImmos && otherImmos.length > 0 &&
    <div className={"col-12 mt-4 mt-xl-5"}>
      <div className="p-relative">

        <h5 className={"mb-2"}>Gewerbeimmobilien</h5>

        <Swiper className={"w-100 " + styles.swiper} loop={false} spaceBetween={20} slidesPerView={'auto'} navigation grabCursor={true}>

          {otherImmos.map(
            i => {
              return <SwiperSlide key={i.id} className={styles.sliderCard}>
                <ImmobilienSliderCard immo={i}/>
              </SwiperSlide>;
            }
          )}
        </Swiper>


        <div className="text-right">
          <RcButton className={"mt-3"}>
            <Link to={"/immobilien/gewerbe"} className={"d-flex text-white align-items-center"}>
              <ChevronRightSVG className={"svgFill"} />
              Alle Gewerbe-Angebote ansehen
            </Link>
          </RcButton>
        </div>

      </div>
    </div>
    }

  </div>;

};

export default ImmobilienSection;
